import styled from "@emotion/styled";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import { srConfig } from "../../config";
import sr from "../../utils/sr";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";

// rankerr background CanvasGradient
// background: rgb(13,13,13);
// background: linear-gradient(
// 350deg
// , rgba(13,13,13,1) 0%, rgba(64,64,64,1) 100%);

const Root = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  margin-right: 1rem;
  margin-left: 1rem;

  @media screen and (min-width: 479px) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  @media screen and (min-width: 767px) {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  @media screen and (min-width: 991px) {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

const ProjectWrapper = styled.div`
  @media screen and (min-width: 991px) {
    width: 50%;
    &:nth-of-type(odd) {
      padding-right: 2.5rem;
    }
    &:nth-of-type(even) {
      padding-left: 2.5rem;
    }
  }
`;

const Project = styled(Link)`
  transform: scale(1);
  width: 100%;
  text-decoration: none;
  color: inherit;
  margin-bottom: 3.75rem;
  display: block;

  .gatsby-image-wrapper {
    margin-bottom: 1.5rem;
    background-color: ${({ bg }) => (bg ? bg : "var(--background-image)")};
    @media screen and (min-width: 479px) {
      margin-bottom: 2rem;
    }
  }

  &:hover {
    .gatsby-image-wrapper > picture > img {
      transform: scale(1.04, 1.04);
      transition: all 0.25s ease-in-out;
    }
  }

  .gatsby-image-wrapper > picture > img {
    transition: all 0.25s ease-in-out;
  }

  @media screen and (min-width: 479px) {
    margin-bottom: 6.25rem;
  }
  @media screen and (min-width: 991px) {
    margin-bottom: 10rem;
  }
`;

const ProjectTitle = styled.p`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  font-family: Lora;
  font-weight: 500;
  line-height: 1em;

  @media only screen and (min-width: 479px) {
    font-size: 1.25rem;
  }
  @media only screen and (min-width: 767px) {
    font-size: 1.5rem;
  }
`;

const ProjectDescription = styled.p`
  font-size: 0.8125rem;
  color: var(--text-secondary);
  @media only screen and (min-width: 479px) {
    font-size: 0.875rem;
  }
  @media only screen and (min-width: 767px) {
    font-size: 0.9375rem;
  }
`;

const Projects = () => {
  const data = useStaticQuery(graphql`
    query Projects {
      allMdx(sort: { fields: [frontmatter___order], order: ASC }) {
        nodes {
          id
          frontmatter {
            title
            description
            slug
            backgroundColor
            thumb {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 50, placeholder: BLURRED, )
              }
            }
          }
        }
      }
    }
  `);

  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }
    revealProjects.current.forEach((ref, i) => {
      sr.reveal(ref, srConfig(100))
    });
  }, []);

  const projects = data.allMdx.nodes;

  return (
    <Root>
      {projects.map((project, i) => (
        <ProjectWrapper key={project.id}>
          <Project
            className="reveal"
            to={"/" + project.frontmatter.slug}

            // bg={project.frontmatter.backgroundColor}
            ref={(el) => (revealProjects.current[i] = el)}
          >
            <GatsbyImage
              image={getImage(project.frontmatter.thumb)}
              alt={project.frontmatter.title}
            />
            <ProjectTitle>{project.frontmatter.title}</ProjectTitle>
            <ProjectDescription>
              {project.frontmatter.description}
            </ProjectDescription>
          </Project>
        </ProjectWrapper>
      ))}
    </Root>
  );
};

export default Projects;
